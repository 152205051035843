<template>
  <div>
    <h2>{{ $t('views.users.locationtrackings.title') }}</h2>
    <p class="h5">{{ $t('views.users.locationtrackings.description') }}</p>

 <div>
    <b-table :items="items" :fields="fields"
      striped
      hover
      show-empty
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @sort-changed="onSortChanged">
      <template #cell(created)="data">
        {{ formatDate(data.value) }}
      </template>
    </b-table>
  </div>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http/core'
import { STOREMODULE_ENDPOINTMODULE_MAP, STOREMODULE_ENDPOINT_MAP } from '@/constants'

export default {
  name: 'Users.Locationtrackings',
  mixins: [MixinEntityBase],
  data () {
    return {
      items: [],
      fields: [
        { key: 'created', label: 'Datum', sortable: true },
        { key: 'name', label: 'Standort', sortable: true },
        { key: 'locationId', label: 'Location ID', sortable: true }
      ],
      sortBy: 'created',
      sortDesc: true
    }
  },
  mounted () {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      http({
        method: 'get',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.userlocationtrackings}/fromuser/${this.eParent.id}/all`
      }).then(response => {
        if (response.data.success === true) {
          this.items = response.data.result
        }
      })
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleString()
    },
    onSortChanged (sortInfo) {
      this.sortBy = sortInfo.field
      this.sortDesc = sortInfo.order === 'desc'
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getUnwrapped`]
    }
  }
}
</script>

<style lang="scss"></style>
